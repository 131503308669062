import { NotificationService } from "@/services/NotificationService";

const state = () => ({
    badgeCount: 0
});

const getters = {
    getBadgeCount: state => state.badgeCount
}

const mutations = {
    setBadgeCount(state, badgeCount) {
        state.badgeCount = badgeCount;
    }
}

const actions = {
    async getBadgeCount({ commit }) {
        try {
            const notificationService = new NotificationService();
            const res = await notificationService.getBadgeCount();
            commit('setBadgeCount', res.data.badgeCount);
        } catch (error) {
            console.error('Error fetching badge count:', error);
            return Promise.reject(error);
        }
    },

    async setBadgeCount({ commit }, badgeCount) {
        try {
            const notificationService = new NotificationService();
            await notificationService.clearBadgeCount();
            commit('setBadgeCount', badgeCount);
        } catch (error) {
            console.error('Error while clear badge count:', error);
            return Promise.reject(error);
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
