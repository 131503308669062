import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDbMt_kJVkFOtH67HK8iqsf4g1GI9-X-ok",
  authDomain: "yescar-crm.firebaseapp.com",
  projectId: "yescar-crm",
  storageBucket: "yescar-crm.appspot.com",
  messagingSenderId: "958420617240",
  appId: "1:958420617240:web:680d25207580505831db6d",
  measurementId: "G-T9LET4M4XE",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
