import axios from "axios"
import { CredentialService } from "./services/CredentialService";
import router from "./router";

axios.defaults.baseURL = `${process.env.VUE_APP_ROOT_API}/api/`;
const credentailService = new CredentialService();

axios.interceptors.request.use(
    config => {
        const authToken = credentailService.credentail?.token;
        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    }
);

axios.interceptors.response.use(
    response => response,
    error => {
        const statusCode = error.response?.status;
        if (statusCode === 401) {
            credentailService.removeCredential();
            const loginPath = "/login";
            if (router.currentRoute.path != loginPath) {
                router.push(loginPath);
            }
        }
        return Promise.reject(error);
    }
);
