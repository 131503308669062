export class CredentialService {

    _credentialKey = "credential";
    _credentail;

    setCredential(credential) {
        localStorage.setItem(this._credentialKey, credential);
    }

    removeCredential() {
        localStorage.removeItem(this._credentialKey);
    }

    get credentail() {
        return JSON.parse(localStorage.getItem(this._credentialKey));
    }

}