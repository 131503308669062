import axios from "axios";

export class NotificationService {

    getBadgeCount() {
        return axios.get("notifications/badge-count")
            .then(res => {
                return res;
            });
    }

    clearBadgeCount() {
        return axios.delete("notifications/clear-badge-count")
            .then(res => {
                return res;
            });
    }

    getNotifications() {
        return axios.get("notifications")
            .then(res => {
                return res;
            });
    }

    clearNotificationById(id) {
        return axios.delete(`notifications/${id}`)
            .then(res => {
                return res;
            });
    }

    clearNotifications() {
        return axios.delete(`notifications`)
            .then(res => {
                return res;
            });
    }
    
}