<template>
  <!-- Main Sidebar Container -->
  <aside
    class="main-sidebar sidebar-light-teal elevation-4"
    style="z-index: 0.0005"
  >
    <!-- Brand Logo -->
    <a href="javascript:void(0)" class="brand-link" style="background-color: rgb(10, 104, 255);padding-top: 15px;"> 
       <span> 
        <a class="brand-image" data-widget="fullscreen" href="javascript:void(0)" role="button">
          <i class="fas fa-expand-arrows-alt" style="color: white;font-size: 13px;"></i>
        </a>&nbsp;
        <a  class="brand-image" data-widget="pushmenu" href="javascript:void(0)" role="button">
          <i class="fas fa-bars" style="color: white;font-size: 13px;"></i>
        </a>
      </span>
    </a>
    <!-- Sidebar -->
    <div class="sidebar">
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <sidebar-menu-item
            :key="page.module"
            :page="page"
            :menu-type="menuType"
            :iconType="iconType"
            v-for="page of pages"
          />
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import store from "@/store";
import SidebarMenuItem from "./SidebarMenuItem.vue";

export default {
  name: "AppSideBar",
  components: {
    SidebarMenuItem,
  },
  mounted() {
    store.dispatch("getPages").then((pages) => {
      this.pages = JSON.parse(JSON.stringify(pages));
    });
  },
  data() {
    return {
      menuType: {
        item: "Item",
        dropdown: "Dropdown",
      },
      iconType: {
        bootstrap: "B_ICON",
      },
      pages: [],
    };
  },
};
</script>

<style lang="scss"></style>
