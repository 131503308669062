import axios from "axios";
import { CredentialService } from "./CredentialService";
import { UserCredential } from "./UserCredential";

export class AuthService {

    _credentailService = new CredentialService();

    login(username, password) {
        return axios.post("auth/login", { username, password })
            .then(res => {
                const user = res.data;
                const credential = new UserCredential(user.id, user.username, user.name, user.token, user.userType);
                this._credentailService.setCredential(JSON.stringify(credential));
                return res;
            });
    }

    logout(deviceToken) {
        return axios.post("auth/logout", { deviceToken })
            .then(res => {
                this._credentailService.removeCredential();
                return res;
            });
    }

    isAuthenticated() {
        return !!this._credentailService.credentail;
    }

}
