
export default {
  data() {
    return {
      isSmallScreen: false
    };
  },
  computed: {

    inputSize() {
      return 'sm';
    },

    xSmall() {
      return {
        'font-size': 'x-small',
      };
    },

  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 991;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  }
};
