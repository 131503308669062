<template>
  <div>
    <!-- <div class="wrapper"></div> -->
    <router-view />
  </div>
</template>
<script>
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebaseConfig";
import { mapActions } from "vuex";
import NotificationAlert from "./shared/components/NotificationAlert.vue";

export default {
  name: "App",
  components: {
    NotificationAlert,
  },
  created() {
    // Listen for incoming messages while the app is in the foreground
    this.listenForMessages();
  },

  methods: {
    ...mapActions(["getBadgeCount"]),
    listenForMessages() {
      onMessage(messaging, (payload) => {
        console.log("Message received:", payload);
        this.showToast({
          title: payload.notification.title,
          content: payload.notification.body,
        });
        this.getBadgeCount();
      });
    },
    showToast(payload) {
      const options = {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      this.$toast(
        {
          component: NotificationAlert,
          props: {
            data: payload,
          },
        },
        options
      );
    },
  },
};
</script>
