export const StatusJobCard = {
    1:'Pending',
    2:'Inspected',
    3:'Quotation',
    4:'Parts Request',
    5:'Ready',
    6:'In Progress',
    7:'Completed'
  };

  export const StatusLeads = { 
    0:'New',
    1:'No Answer',
    2:'Follow-up',
    11:'Cancel',
    4:'Assigned',
    3:'Appointment',
    5:'Closed',
    6:'Booked',
    8:'Loan Rejected',
    9:'Sale Cancel',
  };

  export const StatusLeadSale = { 
    0:'New',
    1:'No Answer',
    2:'Follow-up',
    3:'Appointment',
    4:'Assigned',
    5:'Closed',
    6:'Booked',
    7:'Loan Approved',
    8:'Loan Rejected',
    9:'Sale Cancel',
    10:'Sold',
    11:'Cancel',
  };

  export const SubmissionStatus = {
    6:'Booked',
    7:'Loan Approved',
    8:'Loan Rejected',
    9:'Sale Cancel',
    10:'Sold'
  };

  export const PERMISSION = {
    read: 'read',
    create: 'create',
    udpate: 'update',
    delete: 'delete',
  };
  export const isManagers = {
    isManager: [true, false]
  };

  export const DRIVETRAINS = ['AWD', '4WD', 'RWD', 'FWD'];