import axios from "axios";

export class PermissionService {

    getPermissionByCurrentUser(module) {
        return axios.get("permissions/current-user", { params: { module } })
            .then(res => {
                return res.data;
            });
    }

}
