<template>
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
</template>

<script>
export default {
  name: 'ControlSideBar',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss"></style>