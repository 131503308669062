import axios from "axios";

export class PageService {

    getPages(params = { treeView: false }) {
        return axios.get("pages", { params: params })
            .then(res => {
                return res.data;
            });
    }

    getPagesByCurrentUser() {
        return axios.get("pages/user-pages")
            .then(res => {
                return res.data;
            });
    }

}
