<template>
  <li
    :class="{
      'menu-open': isActive(page),
    }"
    class="nav-item"
    v-if="page"
  >
    <template v-if="page.type === menuType.dropdown">
      <a href="#" class="nav-link" @click="toggleActive(page)">
        <b-icon
          v-if="page.iconType === iconType.bootstrap"
          :icon="page.icon"
        ></b-icon>
        <p class="ml-2">
          {{ page.name }}
          <i class="right fas fa-angle-left"></i>
        </p>
      </a>
    </template>
    <template v-else>
      <router-link :to="page.url ? '/' + page.url : '/'" class="nav-link" @click="toggleActive(page)">
        <b-icon
          v-if="page.iconType === iconType.bootstrap"
          :icon="page.icon"
        ></b-icon>
        <p class="ml-2">
          {{ page.name }}
        </p>
      </router-link>
    </template>
    <ul v-if="page.type === menuType.dropdown" class="nav nav-treeview">
      <sidebar-menu-item
        v-for="child of page.children"
        :key="child.module"
        :page="child"
        :menu-type="menuType"
        :iconType="iconType"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: "SidebarMenuItem",
  props: {
    page: {
      type: Object,
      required: true,
    },
    menuType: {
      type: Object,
      required: true,
    },
    iconType: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleActive(page) {
      page.active = !page.active;
    },
    isActive(page) {
      return page.active;
    },
  },
};
</script>

<style scoped>
/* Add any scoped styles here */

.router-link-exact-active {
  background-color: #ebf0f3;
  color: #007bff !important;
}

</style>
