export class UserCredential {

    constructor(id, username, name, token, userType) {
        this.id = id;
        this.username = username;
        this.token = token;
        this.name = name;
        this.userType = userType;
    }
    
}