import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'


Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VeeValidate from "vee-validate";
import Toasted from 'vue-toasted';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'
//  DataTables 
// import 'admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css'
// import 'admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css'
// import 'admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css'

import 'admin-lte/dist/css/adminlte.min.css'
import './style.scss'


//  Toastr 
import 'admin-lte/plugins/sweetalert2-theme-bootstrap-4/bootstrap-4.min.css'
import 'admin-lte/plugins/toastr/toastr.min.css'


import "admin-lte/plugins/sweetalert2/sweetalert2.min.js"
import "admin-lte/plugins/toastr/toastr.min.js"

import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/dist/js/adminlte.min.js'
import 'flatpickr/dist/flatpickr.css';

 
//  DataTables  & Plugins
// import 'admin-lte/plugins/datatables/jquery.dataTables.min.js'
// import 'admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js'
// import 'admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js'
// import 'admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js'
// import 'admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js'
// import 'admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js'

 
 
/* ------------ Global Components -------------- */
import AppNavBar from './components/partials/AppNavBar'
import AppSideBar from './components/partials/AppSideBar'
import AppFooter from './components/partials/AppFooter'
import ControlSideBar from './components/partials/ControlSideBar'
import GlobalSizeMixin from './constants/GlobalSizeMixin';
import Vuelidate from 'vuelidate'
import permission from './mixin/permission'
import dateFormatMixin from './mixin/dateFormatMixin'

Vue.component('AppNavBar', AppNavBar)
Vue.component('AppSideBar', AppSideBar)
Vue.component('AppFooter', AppFooter)
Vue.component('ControlSideBar', ControlSideBar)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Toasted)
Vue.use(Toast);
Vue.use(Vuelidate);

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});

Vue.filter('formatDate', function (datetime, dateFormat) {
  if (datetime === null) {
    return '-';
  }
  if (!datetime) {
    return '';
  } 
  return format(datetime, dateFormat || 'yyyy-MM-dd HH:mm:ss a');
});

Vue.filter('currency', function (value) {
  let val = (value / 1).toFixed(2);
  return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
});

Vue.filter('percentage', function (value) {
  let val = value.toFixed(2);
  return val + '%';
});

Vue.filter('relativeTime', function (value) {
  const now = dayjs();
  const postedTime = dayjs(value);
  const diffMinutes = now.diff(postedTime, 'minute');
  const diffHours = now.diff(postedTime, 'hour');
  const diffDays = now.diff(postedTime, 'day');

  if (diffMinutes < 1) {
    return 'Just now';
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  } else if (diffDays === 1 && now.date() !== postedTime.date()) {
    return 'Yesterday';
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  } else {
    return postedTime.format('MMM DD, YYYY h:mm A'); // Specific date format
  }
});

Vue.mixin(GlobalSizeMixin);
Vue.mixin(permission);
Vue.mixin(dateFormatMixin);

// Register firebase messaging in service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: 'firebase-cloud-messaging-push-scope' })
    .then((registration) => {
      console.log('ServiceWorker registration successful:', registration);
    })
    .catch((error) => {
      console.error('ServiceWorker registration failed:', error);
    });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


