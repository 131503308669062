import { PageService } from '@/services/PageService';
import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import UserPermission from './UserPermission';
import NotificationStore from './NotificationStore';

const debug = process.env.NODE_ENV !== 'production'


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    pages: null
  },
  mutations: {
    setPages(state, pages) {
      state.pages = pages;
    }
  },
  actions: {
    async getPages({ commit }) {
      try {
        const pageService = new PageService();
        // Fetch permission data from the server
        const pages = await pageService.getPagesByCurrentUser();
        commit('setPages', pages);
        return pages;
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    }
  },
  getters: {
    getPages: state => state.pages,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  modules: {
    userPermission: UserPermission,
    notification: NotificationStore
  }
});

export default store;