<template>
  <li class="nav-item dropdown">
    <a
      class="nav-link"
      data-toggle="dropdown"
      style="display: flex; align-items: center"
      @click="getNotifications"
    >
      <div class="position-relative hover:cursor-pointer">
        <b-icon icon="bell" variant="light"></b-icon>
        <b-badge
          variant="danger"
          class="position-absolute top-0 end-0"
          style="transform: translate(50%, -50%)"
          v-if="getBadgeCount > 0"
          >{{ getBadgeCount }}</b-badge
        >
      </div>
    </a>
    <div
      class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
      style="
        transform: translateX(-50%);
        left: 50%;
        width: 300px;
        max-height: 600px;
        overflow-y: auto;
      "
      @click.stop
    >
      <div
        class="d-flex justify-content-between align-items-center p-2"
        style="position: sticky; top: 0; background-color: white; z-index: 1000"
      >
        <h5 class="m-0">Notifications</h5>
        <!-- <b-button pill variant="outline-danger" size="sm" v-if="notifications.length > 0">
          <b-icon icon="trash" @click="clearNotifications"></b-icon>
        </b-button> -->
      </div>
      <b-dropdown-divider></b-dropdown-divider>
      <div
        class="d-flex align-items-center justify-content-center"
        v-if="notifications.length == 0"
      >
        No notifications
      </div>
      <b-dropdown-item
        v-for="(notification, index) in notifications"
        :key="index"
      >
        <div class="d-flex gap-2 notification-item">
          <div class="avatar">{{ getInitials(notification.sender?.name) }}</div>

          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between align-items-center">
              <div class="content text-wrap" v-if="notification.type == 'LEAD_ASSIGNEE'">
                <strong>{{ notification.sender?.name }}</strong>
                <span> assigned a lead to you.</span>
              </div>

              <div class="content text-wrap" v-if="notification.type == 'REMINDER'">
                <span>{{ notification.message }}</span>
              </div>
              <!-- <b-icon
                variant="danger"
                icon="trash"
                @click="clearNotificationById(notification.id, index)"
              ></b-icon> -->
            </div>
            <b-card style="font-size: 12px" v-if="notification.content">
              <div>
                <span class="text-bold">- Customer Name: </span>
                <span>{{ notification.content.name }}</span>
              </div>
              <div>
                <span class="text-bold">- Status: </span>
                <span>{{ getStatusName(notification.content.status) }}</span>
              </div>
              <div>
                <span class="text-bold">- Telegram: </span>
                <span>{{ notification.content.telegram }}</span>
              </div>
              <div>
                <span class="text-bold">- Phone Number: </span>
                <span>{{ notification.content.phoneNumber }}</span>
              </div>
              <div class="text-wrap">
                <span class="text-bold">- Remark: </span>
                <span>{{ notification.content.remark }}</span>
              </div>
            </b-card>
            <div class="timestamp">{{ notification.date | relativeTime }}</div>
          </div>
        </div>
      </b-dropdown-item>
    </div>
  </li>
</template>
<script>
import { NotificationService } from "@/services/NotificationService";
import store from "@/store";
import { mapGetters, mapActions } from "vuex";
import { StatusLeadSale } from '@/constants/constants';

const notificationService = new NotificationService();

export default {
  name: "NotificationPanel",
  data() {
    return {
      isLoading: false,
      notifications: [],
    };
  },
  methods: {
    ...mapActions(["setBadgeCount"]),
    clearNotifications() {
      notificationService.clearNotifications().then((res) => {
        this.notifications = [];
        this.setBadgeCount(0);
      });
    },
    clearNotificationById(id, index) {
      notificationService.clearNotificationById(id).then((res) => {
        this.notifications.splice(index, 1);
      });
    },
    getNotifications() {
      this.setBadgeCount(0);
      notificationService.getNotifications().then((res) => {
        this.notifications = res.data;
        this.notifications.forEach((notification) => {
          notification.content = JSON.parse(notification.content);
        });
      });
    },
    getInitials(fullName) {
      if (!fullName) return "";
      // Split the full name into an array of words
      const words = fullName.split(" ");

      // Get the first letter of each word and capitalize it
      const initials = words.map((word) => word.charAt(0).toUpperCase());

      // Join the initials together
      return initials.join("");
    },
    getStatusName(statusValue) {
      return StatusLeadSale[statusValue];
    }
  },
  computed: {
    ...mapGetters(["getBadgeCount"]),
  },
  mounted() {
    store.dispatch("getBadgeCount").then((res) => {
      console.log(res);
    });
  },
};
</script>
<style lang="scss">
$avatar-width: 40px;
$avatar-height: 40px;

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.avatar {
  width: $avatar-width;
  height: $avatar-height;
  min-height: $avatar-height;
  min-width: $avatar-width;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}

.notification-item {
  .content > span {
    font-size: 14px;
  }
  .timestamp {
    color: #dc3545;
    font-size: 12px;
  }
}
</style>
