import { PermissionService } from "@/services/PermissionService";

const state = () => ({
    pagePermission: null
});

const getters = {
    getPermission: state => state.pagePermission
}

const mutations = {
    setPermission(state, pagePermission) {
        state.pagePermission = pagePermission;
    }
}

const actions = {
    async getCurrentUserPermission({ commit }, module) {
        try {
            const permissionService = new PermissionService();
            // Fetch permission data from the server
            const permissions = await permissionService.getPermissionByCurrentUser(module);
            if (permissions?.length > 0) {
                commit('setPermission', permissions[0]);
            }
            return permissions;
        } catch (error) {
            console.error('Error fetching permissions:', error);
            return Promise.reject(error);
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
