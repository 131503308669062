import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(['getPermission']),
        pagePermission() {
            return this.getPermission;
        },
    },
    methods: {
        hasPermission(action) {
            return this.pagePermission?.permissions.includes(action);
        },
        isUpdatable(field) {
            return this.pagePermission?.fieldPermission?.[field];
        }
    }
}