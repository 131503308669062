<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light"
    style="padding: 3.5px;background-color: rgb(10 104 255);">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <span v-if="isSmallScreen"> 
        <a class="brand-image" data-widget="fullscreen" href="javascript:void(0)" role="button">
          <i class="fas fa-expand-arrows-alt" style="color: white;font-size: 13px;"></i>
        </a>&nbsp;
        <a  class="brand-image" data-widget="pushmenu" href="javascript:void(0)" role="button">
          <i class="fas fa-bars" style="color: white;font-size: 13px;"></i>
        </a>
      </span>
      <li class="nav-item">
        <img
          src="@/assets/top-logo.png"
          alt="Yescar Logo"
          class="brand-image" style="width: 119px;padding: 0px;"
        />
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <NotificationPanel></NotificationPanel>
      <li class="user-fullname"><span>Welcome {{ userFullName }}</span></li>
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" style="display: flex; align-items: center;">
          <img src="@/assets/profile.png" class="img-circle elevation-2" alt="User Image"
            style="width: 32px;vertical-align: middle;">
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">User Profile</span>
          <div class="dropdown-divider"></div>
          <router-link :to="`/users/profile/${userId}`" class="dropdown-item"><i class="ti-user"></i> Profile</router-link>
          <a href="#" class="dropdown-item" @click="logout"><i class="ti-layout-sidebar-left"></i> Logout</a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import { AuthService } from '@/services/AuthService';
import { CredentialService } from '@/services/CredentialService';
import NotificationPanel from './NotificationPanel.vue';

const authServie = new AuthService();
const credentialService = new CredentialService();

export default {
  name: 'AppNavBar',
  components: {
    NotificationPanel
  },
  data() {
    return {
      userId: null,
      userFullName: ''
    }
  },
  methods: {
    logout() {
      const deviceToken = localStorage.getItem('device-token');
      authServie.logout(deviceToken)
        .then(res => {
          this.$router.push("/login");
          this.$toasted.success(res.data, {
            position: 'top-right',
            duration: 3000,
          });
          localStorage.removeItem('device-token');
        })
        .catch(error => {
          const { status } = error.response;
          if (status === 500) {
              this.$toasted.error(error.response.data.message, {
                position: 'top-right',
                duration: 5000,
              });
          }
        });
    }
  },
  mounted() {
    this.userId = credentialService.credentail?.id;
    this.userFullName = credentialService.credentail?.name;
  }
}
</script>

<style lang="scss">
  .user-fullname {
    align-self: center;
    color: white;
  }
</style>