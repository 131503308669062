<template>
  <div>
    <h6>{{ data.title }}</h6>
    <p>
      {{ data.content }}
    </p>
  </div>
</template>
<script>
export default {
  name: "NotificationAlert",
  props: {
    data: Object,
  },
};
</script>
