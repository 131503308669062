import { AuthService } from '@/services/AuthService'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/',
        name: 'HomePage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/HomeView'),
        meta: { title: 'Dashboard', module: 'dashboard' }
      },
      // setting 
      {
        path: '/brands',
        name: 'BrandPage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/brand/BrandList'),
        meta: { title: 'Brand', module: 'brand' }
      },
      {
        path: '/models',
        name: 'ModelPage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/model/ModelList'),
        meta: { title: 'Model', module: 'model' }
      },
      {
        path: '/colors',
        name: 'ColorPage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/color/ColorList'),
        meta: { title: 'Color', module: 'color' }
      },
      {
        path: '/roles',
        name: 'RolePage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/role/RoleList'),
        meta: { title: 'Role', module: 'role' }
      },
      {
        path: '/users',
        name: 'UserPage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/user/UserList'),
        meta: { title: 'User', module: 'user' },
      },
      {
        path: '/users/profile/:id',
        name: 'UserProfile',
        component: () => import('../views/setting/user/component/UserProfile'),
        meta: { title: 'User Profile', module: 'user' },
      },
      {
        path: '/departments',
        name: 'DepartmentPage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/department/DepartmentList'),
        meta: { title: 'Department', module: 'department' }
      },
      //vehicle 
      {
        path: '/payments',
        name: 'Payments',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/payment/PaymentList'),
        meta: { title: 'Payments', module: 'payment' }
      },
      //customer 
      {
        path: '/sale-submission',
        name: 'sales',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/sales/SubmissionList'),
        meta: { title: 'Sale Submission', module: 'sale-submission' }
      },
      {
        path: '/leads-list',
        name: 'leadsList',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/sales/LeadsList'),
        meta: { title: 'leadsList', module: 'lead-list' }
      },
      //service 
      {
        path: '/leads',
        name: 'leads',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/leads/LeadsList'),
        meta: { title: 'leads', module: 'lead' }
      },
      {
        path: '/inventories',
        name: 'Vehicle',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/inventory/VehicleList'),
        meta: { title: 'Inventory', module: 'inventory' }
      },
      {
        path: '/loan-partner',
        name: 'LoanPartnerPage',
        beforeEnter: beforeEnterHandler,
        component: () => import('../views/setting/loan-partner/LoanPartnerList'),
        meta: { title: 'Loan Partner', module: 'loan-partner' }
      },

    ]
  },
  {
    path: '/login',
    // name: 'DefaultLayout',
    component: () => import('../layouts/BlankLayout'),
    children: [
      {
        path: '/login',
        name: 'LoginPage',
        component: () => import('../views/auth/Login')
      }
    ]
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const authService = new AuthService();
let isAuthenticated;
router.beforeEach((to, from, next) => {
  if (to.path != '/login') {
    isAuthenticated = authService.isAuthenticated();
    if (isAuthenticated) {
      next();
    } else {
      next('login');
    }
  } else if (to.path == '/login') {
    isAuthenticated = authService.isAuthenticated();
    if (isAuthenticated) {
      next('/');
    } else {
      next();
    }
  }

})

async function beforeEnterHandler(to, from, next) {
  await store.dispatch("getCurrentUserPermission", to.meta.module)
    .then(res => {
      const isAccessible = res[0]?.permissions.includes("read");
      isAccessible ? next() : next("/");
    })
    .catch(error => {
      console.error(error);
    });
}

export default router
